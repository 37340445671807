import styled from 'styled-components';

export const CollaborateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
  padding: 100px 30px;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 60px 30px;
  }

  @media screen and (max-width: 480px) {
  }
`

export const CollaborateWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CollaborateIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`

export const CollaborateH1 = styled.h1`
  font-size: 3rem;
  color: #fff;
  margin-bottom: 36px;
  filter: drop-shadow(2px 2px 2px #666666);
        -webkit-filter: drop-shadow(2px 2px 2px #666666);

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`

export const CollaborateH2 = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #fff;
`

export const CollaborateP = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #fff;

  a {
    color: #974EDE;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`