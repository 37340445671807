import React, {useState} from 'react';
import Video from '../../videos/video.mp4';
import MainImg from '../../images/indeno_lg.png';
import { Button } from '../ButtonElements';
import { 
  HeroContainer, 
  HeroBg, 
  VideoBg,
  HeroContent, 
  HeroH1,
  HeroImgWrap,
  HeroImg,
  HeroP,
  HeroBtnWrapper, 
  ArrowForward,
  ArrowRight } from './HeroElements';


const HeroSection = () => {
  const[hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        {/* <HeroH1>
          Indeno
        </HeroH1> */}
        <HeroImgWrap>
          <HeroImg src={MainImg} alt="Indeno Logo" className="floating">
          </HeroImg>
        </HeroImgWrap>
        <HeroP>To Be 'In The Know' Venture</HeroP>
        {/* <HeroBtnWrapper>
          <Button 
          to='signup' 
          onMouseEnter={onHover} 
          onMouseLeave={onHover} 
          primary='true' 
          dark='true'
          smooth={true}
          duration={500}
          spy={true}
          exact='true'
          offset={-80}
          >
            Wait List Full {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper> */}
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection
