import React from 'react';
import UpcomingOne from '../../images/c@n_d1_logox640.jpg';
import UpcomingTwo from '../../images/d1s_d1_logox640.jpg';
import UpcomingThree from '../../images/s@m_d1_logox640.jpg';
import UpcomingFour from '../../images/f3s_d1_logox640.jpg';
import UpcomingFive from '../../images/s@c_d1_logox640.jpg';
import UpcomingSix from '../../images/v1r_d1_logox640.jpg';
import UpcomingSeven from '../../images/sub_d1_logox640.jpg';
import UpcomingEight from '../../images/w3m_d1_logox640.jpg';
import { UpcomingContainer, UpcomingH1, UpcomingWrapper, UpcomingCard, UpcomingIcon, UpcomingH2, UpcomingP } from './UpcomingElements';

const Upcoming = () => {
  return (
    <UpcomingContainer id="upcoming">
      <UpcomingH1>Current Stealth Ventures</UpcomingH1>
      <UpcomingWrapper>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingOne}/>
          <UpcomingH2>Cannabis<br />Network Service<br />Recruitment</UpcomingH2>
        </UpcomingCard>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingTwo}/>
          <UpcomingH2>Record Label<br />Music Purchasing<br />Streaming</UpcomingH2>
        </UpcomingCard>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingThree}/>
          <UpcomingH2>Bootcamp<br />E-Learning<br />Blockchain</UpcomingH2>
        </UpcomingCard>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingFour}/>
          <UpcomingH2>Travel<br />Hospitality<br />E-Commerce</UpcomingH2>
        </UpcomingCard>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingFive}/>
          <UpcomingH2>Eco-Fashion<br />Collaboration<br />Sustainability</UpcomingH2>
        </UpcomingCard>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingSix}/>
          <UpcomingH2>Accelerator<br />Incubator<br />Impact Investing</UpcomingH2>
        </UpcomingCard>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingSeven}/>
          <UpcomingH2>Social Network<br />Collaboration<br />Creative Hub</UpcomingH2>
        </UpcomingCard>
        <UpcomingCard>
          <UpcomingIcon src={UpcomingEight}/>
          <UpcomingH2>AI/ML<br />Recruitment<br />Remote Work</UpcomingH2>
        </UpcomingCard>
      </UpcomingWrapper>
    </UpcomingContainer>
  )
}

export default Upcoming;
