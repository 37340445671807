import React from 'react'
import { 
  SidebarContainer,
  Icon, 
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink, 
  SideBtnWrap,
  SidebarRoute
} from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="ventureOne" onClick={toggle}>Current Ventures</SidebarLink>
          <SidebarLink to="upcoming" onClick={toggle}>Upcoming
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarLink to="collaborate" onClick={toggle}>Collaborate</SidebarLink>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar
