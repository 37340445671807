import React, {useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import LogoHorizontal from '../../images/indeno_horizontal_640.png';
import { animateScroll as scroll} from 'react-scroll';
import { 
  Nav, 
  NavbarContainer, 
  NavLogo, 
  NavImg,
  MobileIcon, 
  NavMenu, 
  NavItem, 
  NavLinks,
  NavBtn,
  NavBtnLink 
} from './NavbarElements';


const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (    
    <>
      <IconContext.Provider value ={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome}>
              <NavImg src={LogoHorizontal}>
              </NavImg>
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              {/* <NavItem>
                <NavLinks 
                to="ventureOne"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                >Current Ventures</NavLinks>
              </NavItem> */}
              <NavItem>
                <NavLinks 
                to="upcoming"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                >Current Stealth Ventures</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                to="collaborate"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                >Collaborate</NavLinks>
              </NavItem>
            </NavMenu>
            {/*
            <NavBtn>
              <NavBtnLink to="/signin">Wait List Full</NavBtnLink>
            </NavBtn> 
            Commenting out for now */}
            
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;