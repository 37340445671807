import styled from 'styled-components';
import { Link } from 'react-scroll';

export const Button = styled(Link)`
  border-radius: 50px;
  background: #fff;
  background: ${({primary}) => (primary ? '#fff' : '#fff')};
  background: ${({goldBtn}) => (goldBtn ? 'linear-gradient(0deg, rgba(219,146,35,1) 0%, rgba(252,253,124,1) 100%)' : 'linear-gradient(0deg, rgba(51,51,51,1) 0%, rgba(221,24,24,1) 100%)')};
  background: ${({greenBtn}) => (greenBtn ? 'linear-gradient(0deg, rgba(30,102,78,1) 0%, rgba(82,186,88,1) 100%)' : `{goldBtn}`)};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
  color: ${({ dark }) => (dark ? '#fff' : '#010606')};
  font-size: ${({ fontBig }) => (fontBig ? '24px' : '18px')};
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-out;
  padding: 15px 30px 10px;
  max-width: 480px;
  width: 100%;

  &:hover {
    transform: scale(1.07);
  }

  @media screen and (max-width: 1080px) {
    padding: 15px 30px 10px;
    min-width: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
    max-width: none;
  }

  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`

export const BtnWrap = styled.div`
  padding: 0 30px;

`