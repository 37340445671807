export const homeObjOne = {
  id: 'ventureOne',
  ventureUrl: 'https://www.apesofmoass.com/',
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'NFT ● Art ● Fashion ',
  headline: 'APES OF M.O.A.S.S.',
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius duis at consectetur lorem donec massa sapien faucibus. Habitant morbi tristique senectus et. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi..",
  buttonLabel: 'View Venture',
  imgStart: true,
  img: require('../../images/framed_aom_1000.png'),
  alt: 'Ape',
  dark: true, 
  primary: true,
  darkText: false,
  goldBtn: true, 
  greenBtn: false
};

export const homeObjTwo = {
  id: 'ventureTwo',
  ventureUrl: 'https://www.kyureshon.com',
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'NFT ● Automotive ● Collectible ',
  headline: 'Kyurēshon',
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius duis at consectetur lorem donec massa sapien faucibus. Habitant morbi tristique senectus et. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi.",
  buttonLabel: 'View Venture',
  imgStart: false,
  img: require('../../images/kyureshon_logo_shadowx1280.png'),
  alt: 'Kyureshon Logo',
  dark: true, 
  primary: true,
  darkText: false,
  goldBtn: false,
  greenBtn: false
};

export const homeObjThree = {
  id: 'ventureThree',
  ventureUrl: 'https://www.villpala.com',
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Travel ● Outdoors ● Vacation Rental',
  headline: 'Villpala',
  description: "Nunc congue nisi vitae suscipit. Suspendisse ultrices gravida dictum fusce ut placerat orci. Interdum velit laoreet id donec ultrices tincidunt arcu non. Commodo sed egestas egestas fringilla. Nisl purus in mollis nunc sed id semper risus. Vulputate sapien nec sagittis aliquam malesuada. Est placerat in egestas erat imperdiet sed euismod nisi porta. Fermentum posuere urna nec tincidunt praesent semper. ",
  buttonLabel: 'View Venture',
  imgStart: true,
  img: require('../../images/villpala_logox1280.png'),
  alt: 'Paper',
  dark: true, 
  primary: true,
  darkText: false, 
  goldBtn: false,
  greenBtn: true,
};