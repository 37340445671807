import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { 
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaDiscord,
  FaLinkedin
} from 'react-icons/fa';
import { FooterContainer, 
  FooterWrap, 
  FooterLinksContainer, 
  FooterLinksWrapper, 
  FooterLinkItems, 
  FooterLinkTitle, 
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo, 
  WebsiteRights,
  SocialIcons, 
  SocialIconLink
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer id='footer'>
      <FooterWrap>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to='/' onClick={toggleHome}>
                Indeno
              </SocialLogo>
              <WebsiteRights>Indeno &copy; {new Date().getFullYear()} All rights reserved.</WebsiteRights>
              {/* <SocialIcons>
                <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" aria-label="twitter">
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" aria-label="FaDiscord">
                  <FaDiscord />
                </SocialIconLink>
              </SocialIcons> */}
            </SocialMediaWrap>
          </SocialMedia>
      </FooterWrap>      
    </FooterContainer>
  )
}

export default Footer
