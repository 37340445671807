import styled from 'styled-components';

export const InfoContainer = styled.div`
  color: #fff;
  display: flex;
  min-height: 650px;
  background: ${({ lightBg }) => (lightBg ? '#fff' : '#010606')};
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  @media screen and (max-width: 768px) {
    min-height: none;
  }
`;


export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    padding: 100px 15px;
  }

  @media screen and (max-width: 768px) {
    padding: 80px 30px;
    min-height: none;
  }

  @media screen and (max-width: 480px) {
    padding: 60px 10px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2' 'col1'` : ` 'col2 col2' 'col1 col1'`)};
  }
`;

export const Column1 = styled.div`
  padding: 0 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: col1;

  @media screen and (max-width: 960px) {
    padding: 0 5px;
  }
`;

export const Column2 = styled.div` 
  padding: 0 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: col2;

  @media screen and (max-width: 960px) {
    padding: 0 5px;
  }
`;

export const TextWrapper = styled.div`
  /* max-width: 540px; */
  padding: 0px;

  @media screen and (max-width: 768px) {
    padding: 30px 0 0;
  }
`;

export const TopLine = styled.p`
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 18px;

  @media screen and (max-width: 768px) {
    margin-bottom: 26px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }

`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

  @media screen and (max-width: 960px) {
    font-size: 36px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 34px;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 35px;
  font-size: 20px;
  line-height: auto;
  color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  display: flex;
  max-width: 540px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0;
  padding-right: 0;
`;
