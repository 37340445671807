import styled from 'styled-components';

export const UpcomingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 100px 30px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */


  @media screen and (max-width: 768px) {
    padding: 60px 30px;
  }

  @media screen and (max-width: 480px) {
    padding: 60px 10px;
  }
`

export const UpcomingWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  transition: all 0.5s ease-in-out;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 50px;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
  }

`

export const UpcomingCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: scale(1.04);
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    h2 {
      color: #fff;
    }
  }

`

export const UpcomingIcon = styled.img`
  width: 100%;
`

export const UpcomingH1 = styled.h1`
  font-size: 3rem;
  color: #fff;
  margin-bottom: 64px;
  filter: drop-shadow(3px 3px 3px #666666);
        -webkit-filter: drop-shadow(3px 3px 3px #666666);

  @media screen and (max-width: 600px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`

export const UpcomingH2 = styled.h2`
  color: transparent;
  position: absolute;
  font-size: 2rem;
  text-align: center;
  transition: all .8s ease-in;
  text-transform: uppercase;
  font-weight: 700;
  filter: drop-shadow(2px 2px 2px #666666);
        -webkit-filter: drop-shadow(2px 2px 2px #666666);

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }    
`

export const UpcomingP = styled.h2`
  font-size: 1rem;
  text-align: center;
`