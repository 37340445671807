import React from 'react';
import { CollaborateContainer, CollaborateH1, CollaborateWrapper, CollaborateCard, CollaborateH2, CollaborateP } from './CollaborateElements';

const Collaborate = () => {
  return (
    <CollaborateContainer id="collaborate">
      <CollaborateWrapper>
        <CollaborateH1>Collaborate for Change</CollaborateH1>
        <CollaborateP>We are a community of 'in the know' investors, experience creators, and pay-it-forward entrepreneurs - volunteering, campaigning, and supporting initiatives that move humanity forward. If you would like to discuss and collaborate on upcoming future projects, please do not hesitate to send us a message over at <a href = "mailto: collaborate@indeno.xyz?subject = Collaborate">collaborate@indeno.xyz</a></CollaborateP>
      </CollaborateWrapper>
    </CollaborateContainer>
  )
}

export default Collaborate;
