import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';


export const Nav = styled.nav `
  background: ${({scrollNav}) => (scrollNav ? '#fff' : 'transparent')};
  height: 80px;
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.3s all ease;

  @media screen and (max-width: 960px){
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div `
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;

  @media screen and (max-width: 480px) {
    padding: 0 10px;
  }
`;

export const NavLogo =  styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5 rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
`;

export const NavImg = styled.img`
  height: 30px;
`


export const MobileIcon = styled.div`
  display: none;

  svg {
    color: #2c1a52;
    font-size: 3rem;

    path {
      color: #2c1a52;
    }
  }

  @media screen and (max-width: 768px){
    display: block;
    position: absolute;
    top: -17px;
    right: -20px;
    transform: translate(-100%, 60%);
    cursor: pointer;
  }

  @media screen and (max-width: 480px){
    top: -20px;
    right: -35px;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items:center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #3d2765;
  }
`;

export const NavItem = styled.li`
  height: 80px;

  a {
    color: #3d2765;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding-top: 25px;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #000;
      transition: width .3s;
    }

    &:hover::after {
      width: 100%;
    }
  }
`;


export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex; 
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #01bf71;
  }
`;


export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px){
    display: none;
  }
`;


export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`



